<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.discount.detail.users }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Discount')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol md="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.discount.form.code
                    }}</label>
                  <v-select
                      :options="codeOptions"
                      v-model="filter.code"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>
          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="/discount/users/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Discount')"
          >
            <template #startDate="data">
              {{ getDateWithFormat(data.row.startDate) }}
            </template>
            <template #endDate="data">
              {{ getDateWithFormat(data.row.endDate) }}
            </template>
            <template #actions="data">
              <CButton
                  color="warning"
                  v-c-tooltip="$lang.discount.crud.view"
                  v-on:click="viewTableRow(data.row.id)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {discount, users} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import BeforeFilter from "../BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "DiscountUsers",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
  },
  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      activePage: 1,
      module: discount,
      moduleUser: users,
      filter: {
        code: "",
      },
      columns: ["name", "code", "startDate", "endDate", "actions"], //'status'
      data: [],
      codeOptions: [],
      options: {
        headings: {
          code: this.$lang.discount.table.code,
          startDate: this.$lang.discount.table.startDate,
          endDate: this.$lang.discount.table.endDate,
          name: this.$lang.discount.table.name,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "code", "startDate", "endDate"],
        sortable: ["name", "code", "startDate", "endDate"],
        filterable: ["name", "code", "startDate", "endDate"],
      },
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    axios.get(this.listUrlApi(this.module)).then((response) => {
      response.data.data.map(function (value, key) {
        self.codeOptions.push({value: value.id, label: value.code});
      });
    });
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                }
              });
        }
      });
    },
    viewTableRow(item) {
      this.$router.push({path: `discount-users/view/${item}`});
    },
    applyFilter() {
      let reqObj = {
        discountId:
            this.filter.code !== null && Object.keys(this.filter.code).length > 0
                ? this.filter.code.value
                : "",
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      this.filter.code = {};
      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
